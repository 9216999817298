const roleMapping = {
    1: "Admin",
    2: "Editor",
    3: "Viewer",
    4: "Digital Ops",
  };
  
  const getRoleNames = (roleData) => {
    if (!roleData) return "Unknown Role";
  
    let roleArray = [];
  
    if (typeof roleData === "string") {
      roleArray = roleData.split(",");
    } else if (Array.isArray(roleData)) {
      roleArray = roleData.map(String);
    } else if (typeof roleData === "object") {
      return "Invalid Role Format";
    }
  
    return roleArray
      .map((role) => roleMapping[role] || "Unknown Role")
      .join(", ");
  };
  
  const roleOptions = [
    { label: "Admin", value: "1" },
    { label: "Editor", value: "2" },
    { label: "Viewer", value: "3" },
    { label: "Digital Ops", value: "4" },
  ];
  
  export { roleMapping, getRoleNames, roleOptions };