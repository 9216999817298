import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useLocation, useNavigate, useParams } from 'react-router';

import Axios from 'api/Axios';
import { API } from 'api/API';
import Loading from 'components/Loading';
import Message from 'components/Snackbar/Snackbar';

const View_PDF_Screen = ({ ...others }) => {
  const location = useLocation();
  const GetItem = localStorage.getItem('Profile_Details');
  const Details = JSON.parse(GetItem);
  const navigate = useNavigate();
  const WallboardName = localStorage.getItem('wallboard_name');
  const { Wallboard_name } = location.state || {};
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [isLoading, setIsLoading] = useState(false);
  const [pdf_File, setPdfFile] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    // severity: "",
  });

  const params = useParams();

  const PDF_URL = async () => {
    const id = params.id;
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Get_Pdf_Path, { docId: id });
      if (result.status === 200) {
        setIsLoading(false);
        setPdfFile(result.data.pdfPath);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err, 'This is Error');
      setSnackbar({
        open: true,
        severity: 'error',
        message: err.response.data.message,
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
      severity: snackbar.severity,
    });
  };

  useEffect(() => {
    PDF_URL();
  }, []);

  const Approval_Status = async (approval_status, ScreenType) => {
    const message = 'Disapprove';
    try {
      const id = params.id;
      setIsLoading(true);
      const formData = new FormData();
      formData.append('id', id);
      formData.append('approvalStatus', approval_status.toString());
      // formData.append("template_theme_name", data.color);
      if (approval_status == 2) {
        formData.append('reason', message);
      }
      const result = await Axios.Filepost(API.Approve_and_Disapprove_Wallboard, formData);
      if (result.status === 200) {
        setIsLoading(false);
        setSnackbar({
          open: true,
          message:
            ScreenType === 'Pending' ? 'Wallboard Moved to the Pending' : result.data.message,
          severity: 'success',
        });
        setTimeout(() => {
          navigate(
            ScreenType === 'Reject'
              ? '/selectwallboard/rejected'
              : ScreenType === 'Pending' && '/selectwallboard/pending'
          );
        }, 3000);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err, 'This is Error');
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'An error occurred',
        severity: 'error',
      });
    }
  };

  const handlePending = async () => {
    await Approval_Status(0, 'Pending');
  };

  const handleDisApprove = async () => {
    await Approval_Status(2, 'Reject');
  };

  return (
    <MainCard
      className="showVersions"
      title={Wallboard_name || WallboardName}
      {...(Details.role !== '3' && {
        approve: handlePending,
        buttontitle1: 'Pending',
        approveColor: '#15223f',
        disapprove: handleDisApprove,
        buttontitle2: 'Reject',
        disapprovecolor: '#F44336',
      })}
    >
      <div className="container">
        <div className="viewer">
          {pdf_File ? (
            <iframe
              className="pdf_viewer"
              title="PDF Viewer"
              src={pdf_File}
              width="100%"
              height="800px"
            ></iframe>
          ) : (
            <p>Loading PDF...</p>
          )}
          {/* <iframe
            title="PDF Viewer"
            src={pdf_File}
            width="100%"
            height="800px"
          ></iframe> */}
          {/* <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
            <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
          </Worker> */}
        </div>
      </div>
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
    </MainCard>
  );
};

export default View_PDF_Screen;
