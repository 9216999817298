import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import NotFound from "./NotFound";
import ForgotPassword from "views/pages/authentication/forgot-password/ForgotPassword";
import ResetPassword from "views/pages/authentication/reset-password/ResetPassword";
import TokenExpire from "./TokenExpire";

// login option 3 routing
const AuthLogin3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
const AuthRegister3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Register3"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: <AuthLogin3 />,
    },
    {
      path: "/register/",
      element: <AuthRegister3 />,
    },
    {
      path: '/forgot-password/',
      element: <ForgotPassword/>
    },
    {
      path: '/reset-password/',
      element: <ResetPassword/>
    },
    {
      path: '/token-Expire',
      element: <TokenExpire/>
    },
    {
      path: "/*",
      element: <NotFound />,
    },
  ],
};

export default AuthenticationRoutes;
