const STAGING = "preview"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "18r267tbnk1vlcily76kth64sgpmcojabq6teob9pjglbuvm",
  dev: "18r267tbnk1vlcily76kth64sgpmcojabq6teob9pjglbuvm",
  prod: "igapu4iaanwldgbzsinazy7hdlhptwh690usmhrkmly21rwa",
  preview: "6qb0bc3j8eoghsjlv26dpimgcpn112qc4lglgybddubrhgjq",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}