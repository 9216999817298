import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FaFileCsv, FaFilePdf } from 'react-icons/fa';
import { months, years } from 'utils/Fields';
import Axios from 'api/Axios';
import { API } from 'api/API';
import { Hanger_Comps, requiredFields } from '../PrePrint/Fields';
import Papa from 'papaparse';

const fields = [
  { name: 'Year', label: 'Year', options: 'Year', width: 6 },
  { name: 'Month', label: 'Month', options: 'Month', width: 6 },
];

const fileFields = [
  { name: 'rwo', label: 'Upload RWO' },
  { name: 'comp', label: 'Upload Comp' },
  { name: 'hanger', label: 'Upload Hanger' },
  { name: 'rolled', label: 'Upload Rolled' },
];

const numberFields = [
  { name: 'printorder', label: 'Print Order' },
  { name: 'batchNumber', label: 'Batch Number' },
];

const validationSchema = Yup.object().shape({
  Year: Yup.mixed().required('Year is required'),
  Month: Yup.mixed().required('Month is required'),
  rwo: Yup.mixed().required('RWO File is required'),
  printorder: Yup.number()
    .required('Print order is required')
    .min(1, 'Print order must be at least 1')
    .max(9, 'Print order must be at most 9'),
  batchNumber: Yup.number()
    .required('Batch Number is required')
    .min(1, 'Batch Number must be at least 1')
    .max(9, 'Batch Number must be at most 9'),
});

const CreatePO = ({
  setIsLoading,
  isLoading,
  close,
  setSnackbar,
  Get_All_listings,
  // setChildRowExpansion,
  // setRowExpansion,
  RowClose,
  ...others
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const FilterData = {
    Year: years,
    Month: months,
  };

  const AllValues = {
    Year: '',
    Month: '',
    rwo: '',
    comp: '',
    hanger: '',
    rolled: '',
    printorder: '1',
    batchNumber: '1',
  };

  // const handleSubmit = async (values) => {
  //   const data = {
  //     merged_name: `${values.Month} ${values.Year}`,
  //     month: values.Month,
  //     year: values.Year,
  //     post_order: values.printorder,
  //     batch_number: values.batchNumber,
  //   };
  //   try {
  //     setIsLoading(true);
  //     const result = await Axios.post(API.PrePrintModule, data);
  //     if (result.status === 201 || result.status === 402 || result.status === 403) {
  //       // console.log(result.data, "hello I am in");
  //       const formData = new FormData();
  //       const Filesdata = {
  //         post_order: values.printorder,
  //         batch_number: values.batchNumber,
  //         pre_print_merge_id: result.data.newMergename.pre_print_id,
  //         merged_name: data.merged_name,
  //         csvfile: values.rwo,
  //         compfile: values?.comp,
  //         hangerfile: values?.hanger,
  //         rolledfile: values?.rolled,
  //       };
  //       // Append each key-value pair to formData
  //       for (const key in Filesdata) {
  //         if (Filesdata.hasOwnProperty(key)) {
  //           formData.append(key, Filesdata[key]);
  //         }
  //       }
  //       const response = await Axios.Filepost(API.ADD_CSV, formData);
  //       if (response.status === 200) {
  //         setIsLoading(false);
  //         setSnackbar({
  //           open: true,
  //           message: response.data.message,
  //           severity: 'success',
  //         });
  //         await Get_All_listings();
  //         RowClose();
  //         close();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error, 'Error while getting API Response');
  //     setIsLoading(false);
  //     setSnackbar({
  //       open: true,
  //       message: error.response.data.error || error.message,
  //       severity: 'error',
  //     });
  //   }
  // };

  const handleSubmit = async (values) => {
    const data = {
      merged_name: `${values.Month} ${values.Year}`,
      month: values.Month,
      year: values.Year,
      post_order: values.printorder,
      batch_number: values.batchNumber,
    };
    
    try {
      setIsLoading(true);
      let result;
  
      // Attempt API call inside a try block to handle errors like 402 or 403
      try {
        result = await Axios.post(API.PrePrintModule, data);
      } catch (error) {
        // Check for 402 or 403 errors and allow them to proceed
        if (error.response && (error.response.status === 402)) {
          result = error.response; // Assign the error response to result to proceed
        } else {
          throw error; // For other errors, throw to be caught below
        }
      }
  
      // Proceed if status is 201, 402
      if (result.status === 201 || result.status === 402) {
        const formData = new FormData();
        const Filesdata = {
          post_order: values.printorder,
          batch_number: values.batchNumber,
          pre_print_merge_id: result.status === 402 ? result.data?.pre_print_id : result.data.newMergename.pre_print_id,
          merged_name: data.merged_name,
          csvfile: values.rwo,
          compfile: values?.comp,
          hangerfile: values?.hanger,
          rolledfile: values?.rolled,
        };
  
        // Append each key-value pair to formData
        for (const key in Filesdata) {
          if (Filesdata.hasOwnProperty(key)) {
            formData.append(key, Filesdata[key]);
          }
        }
  
        // Submit the form data via another API call
        const response = await Axios.Filepost(API.ADD_CSV, formData);
  
        if (response.status === 200) {
          setIsLoading(false);
          setSnackbar({
            open: true,
            message: response.data.message,
            severity: 'success',
          });
          await Get_All_listings();
          RowClose();
          close();
        }
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error.response?.data?.error,
        severity: 'error',
      });
    }
  };
  

  const handleFileChange = (event, field, values, setFieldValue) => {
    // Check if any required field is empty and build the error message
    let missingFields = [];
    if (!values.Year) missingFields.push('Year');
    if (!values.Month) missingFields.push('Month');
    if (!values.printorder) missingFields.push('Print Order');
    if (!values.batchNumber) missingFields.push('Batch Number');

    if (missingFields.length > 0) {
      setSnackbar({
        open: true,
        message: `Please add these fields first: ${missingFields.join(', ')}`,
        severity: 'error',
      });
      event.target.value = '';
      // return; // Exit the function early since the fields are missing
    } else {
      const file = event.target.files[0];

      if (file && file.name.toLowerCase().endsWith('.csv')) {
        const expectedFileName =
          (field === 'rwo' &&
            `${values.Month} ${values.Year} PO ${values.printorder} RWO_Batch ${values.batchNumber}.csv`) ||
          (field === 'hanger' &&
            `${values.Month} ${values.Year} PO ${values.printorder} RWO_Batch ${values.batchNumber} Hangers.csv`) ||
          (field === 'comp' &&
            `${values.Month} ${values.Year} PO ${values.printorder} RWO_Batch ${values.batchNumber} Comps and Letters.csv`) ||
          (field === 'rolled' &&
            `${values.Month} ${values.Year} PO ${values.printorder} RWO_Batch ${values.batchNumber} Rolled.csv`);

        if (file.name === expectedFileName) {
          Papa.parse(file, {
            complete: (result) => {
              const data = result.data[0];
              const allFieldsPresent =
                (field === 'rwo' && requiredFields.every((field) => data.hasOwnProperty(field))) ||
                (field === 'hanger' && Hanger_Comps.every((field) => data.hasOwnProperty(field))) ||
                (field === 'comp' && Hanger_Comps.every((field) => data.hasOwnProperty(field))) ||
                (field === 'rolled' && requiredFields.every((field) => data.hasOwnProperty(field)));

              if (!allFieldsPresent) {
                setSnackbar({
                  open: true,
                  severity: 'warning',
                  message: 'Selected file does not match the required format.',
                });
                setFieldValue(field, undefined);
                event.target.value = '';
              } else {
                setFieldValue(field, file);
              }
            },
            header: true, // Assumes the first row in the CSV file contains column headers
          });
        } else {
          setSnackbar({
            open: true,
            severity: 'warning',
            message: `File name does not match. The suggested file name is ${expectedFileName}.`,
          });
          setFieldValue(field, undefined);
          event.target.value = '';
        }
      } else {
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Only CSV files are accepted.',
        });
        setFieldValue(field, undefined);
        event.target.value = '';
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={AllValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // console.log(values, "hello values");
          handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others} autoComplete="off">
            <Grid
              container
              spacing={matchDownSM ? 0 : 2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.template && errors.template)}
                  sx={{ ...theme.typography.customInput, margin: 0 }}
                >
                  <FormControl
                    style={{ display: 'flex', flexDirection: 'row', gap: 10, marginTop: 12 }}
                  >
                    {fields.map((field) => (
                      <Grid
                        item
                        xs={field.width}
                        style={{ marginTop: field?.marginTop }}
                        key={field.name}
                      >
                        <FormControl fullWidth>
                          <Autocomplete
                            disablePortal
                            autoHighlight
                            options={FilterData[field.name] || []}
                            getOptionLabel={(option) => option || ''}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={field.label}
                                variant="outlined"
                                style={{
                                  border: Boolean(touched[field.name] && errors[field.name])
                                    ? '1px solid red'
                                    : '',
                                  borderRadius: Boolean(touched[field.name] && errors[field.name])
                                    ? '12px'
                                    : '',
                                }}
                                // InputLabelProps={{
                                //   style: {
                                //     background: '#fff',
                                //   },
                                // }}
                              />
                            )}
                            name={field.name}
                            value={
                              values[field.name]
                                ? FilterData[field.options].find(
                                    (item) => item === values[field.name]
                                  )
                                : ''
                            }
                            onChange={(_, newValue) => {
                              setFieldValue(field.name, newValue ? newValue : '');
                            }}
                            noOptionsText="No Results Found"
                            error={Boolean(touched[field.name] && errors[field.name])}
                            // sx={{border: Boolean(touched[field.name] && errors[field.name]) ? '1px solid red' :""}}
                            // helperText={touched[field.name] && errors[field.name]}
                          />
                          {touched[field.name] && errors[field.name] && (
                            <FormHelperText error id={`standard-weight-helper-text-${field.name}`}>
                              {errors[field.name]}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    ))}
                  </FormControl>
                </FormControl>
              </Grid>

              {numberFields.map((field) => (
                <Grid item xs={12} sm={6} key={field.name}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched[field.name] && errors[field.name])}
                    sx={{ ...theme.typography.customInput, 
                      height: '80px', 
                    }}
                  >
                    <InputLabel htmlFor={`outlined-adornment-${field.name}`}>
                      {field.label}
                    </InputLabel>
                    <OutlinedInput
                      id={`outlined-adornment-${field.name}`}
                      type="number"
                      value={values[field.name]}
                      name={field.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{}}
                      onKeyPress={(e) => {
                        if (e.key === 'e') {
                          e.preventDefault();
                        }
                      }}
                    />
                    {touched[field.name] && errors[field.name] && (
                      <FormHelperText error id={`standard-weight-helper-text-${field.name}`}>
                        {errors[field.name]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}

              {/* Files */}
              {fileFields.map((field) => (
                <Grid item xs={12} style={{paddingTop: field.name=== "rwo" && 0}} key={field.name}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched[field.name] && errors[field.name])}
                    sx={{ ...theme.typography.customInput, marginTop:0}}
                  >
                    <InputLabel htmlFor={`outlined-adornment-${field.name}`}>
                      {field.label}
                    </InputLabel>
                    <Input
                      id={`outlined-adornment-${field.name}`}
                      type="file"
                      name={field.name}
                      inputProps={{
                        accept: '.csv',
                      }}
                      // onBlur={handleBlur}
                      // onChange={(e) => {
                      //   setFieldValue(field.name, e.currentTarget.files[0]);
                      // }}
                      onChange={(event) =>
                        handleFileChange(event, field.name, values, setFieldValue)
                      }
                      endAdornment={
                        <InputAdornment sx={{ fontSize: 22, marginTop: 2 }} position="end">
                          <FaFileCsv />
                        </InputAdornment>
                      }
                    />
                    {touched[field.name] && errors[field.name] && (
                      <FormHelperText error id={`standard-weight-helper-text-${field.name}`}>
                        {errors[field.name]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>

            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <Button
                size="large"
                variant="contained"
                style={{
                  background: '#C62828',
                  marginRight: '10px',
                  color: isLoading === true && '#fff',
                }}
                onClick={close}
                disabled={isLoading === true ?? true}
              >
                Close
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                style={{
                  background: '#1d213e',
                  marginRight: '15px',
                  color: isLoading === true && '#fff',
                }}
                disabled={isLoading === true ?? true}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreatePO;
