import React, { useState } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import useScriptRef from "hooks/useScriptRef";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import MainCard from "ui-component/cards/MainCard";
import Axios from "api/Axios";
import { API } from "api/API";
import Message from "components/Snackbar/Snackbar";
import Loading from "components/Loading";
import { roleOptions } from "functions/RoleUtils";
import ConfirmationAlert from "components/ConfirmationAlert";
import { useNavigate } from "react-router";

const InviteUser = ({ ...others }) => {
  const navigate = useNavigate()
  const scriptedRef = useScriptRef();
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    // severity: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [navigateUserId, setnavigateUserId] = useState(null);

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.common.white,
    },
  }));
  const classes = useStyles(); // Don't forget to call useStyles

  const handleResendInvite = async (e, values, resetForm) => {
    e.preventDefault();
    const payload = {
      email: values.email,
      role: values.role ? values.role.map((item) => item.value).toString() : [],
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Resend_Invite, payload);
      if (result.status === 200) {
        setSnackbar({
          open: true,
          severity: "success",
          message: "Invite resent successfully!",
        });
        resetForm();
      }
    } catch (err) {
      console.log(err);
      setSnackbar({
        open: true,
        severity: "error",
        message:
          err.response?.data?.error ||
          err.response?.data?.message ||
          "Something went wrong",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const Edit_User = () => {
  navigate(`/user-management/edit-user/${navigateUserId}`)
  }

  return (
    <MainCard title="Invite New User">
      {/* <ComingSoon />  */}
      <div className={classes.root}>
        <Formik
          initialValues={{
            email: "",
            role: [],
            resend_invite:false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup?.string()
              ?.email("Must be a valid email")
              ?.max(255)
              ?.required("Email is required"),
            role: Yup.array().min(1, "At least one role is required"),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }
          ) => {
            const payload = {
              email: values.email,
              role: values.role.map((item) => item.value).toString(),
            };
            try {
              setIsLoading(true);
              const result = await Axios.post(API.Invite_New_User, payload);
              if (result.status === 200) {
                setSnackbar({
                  open: true,
                  severity: "success",
                  message: result.data.message,
                });
                resetForm();
                setIsLoading(false);
              }
            } catch (err) {
              setIsLoading(false);
              console.log(err);
              if (err.response.data.message !== "User already exists") {
              setSnackbar({
                open: true,
                severity: "error",
                message: err.response.data.error || err.response.data.message,
              });
            }
              if(err.response.data.message === "User already exists"){
                setShowConfirmation(true);
         
                setnavigateUserId(err.response.data.Userid)
              }
              if(err.response.data.resend_mail === true){
                setFieldValue('resend_invite', true);
              }
              if (scriptedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setErrors,
            validateForm,
            setTouched,
            isSubmitting,
            resetForm,
            touched,
            values,
          }) => (
            <form
              className={classes.forms}
              noValidate
              onSubmit={handleSubmit}
              // onSubmit={(e) => handleFormSubmit(e, handleSubmit)}
              {...others}
              autoComplete="off"
            >
              {/* Email */}
              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="outlined-adornment-email">
                  Email 
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  // onChange={handleChange}
                  onChange={(e) => {
                    handleChange({
                      target: { name: "email", value: e.target.value.trimStart() },
                    });
                  }}
                  label="Email  "
                  inputProps={{}}
                />
                {touched.email && errors.email && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>

              {/* Role Select */}
              <FormControl
                style={{ marginTop: 10 }}
                fullWidth
                error={Boolean(touched.role && errors.role)}
              >
                <Autocomplete
                  multiple
                  id="outlined-roles"
                  options={roleOptions}
                  value={values.role || []}
                  disableCloseOnSelect
                  onChange={(_, value) => {
                    handleChange({ target: { name: "role", value } });
                  }}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option.label}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Roles "
                      InputLabelProps={{
                        style: { marginTop: "4px" }, // Added margin-top to label
                      }}
                      style={{
                        border:
                          Boolean(touched.role && errors.role) &&
                          "1px solid red",
                        borderRadius: 12,
                        minHeight: "60px",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { minHeight: "60px" },
                      }}
                    />
                  )}
                  style={{ padding: "3px 0" }}
                />

                {touched.role && errors.role && (
                  <FormHelperText error>{errors.role}</FormHelperText>
                )}
              </FormControl>

              <Box
                sx={{ mt: 2 }}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{
                    background: "#15223F",
                    color: isSubmitting ? "#fff" : "#fff",
                  }}
                >
                  Invite User 
                </Button>
                <Button
                  disableElevation
                  disabled={isSubmitting || !values.resend_invite}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{
                    background: !values.resend_invite ? "#B0B0B0" : "#c62828",
                    color: "#fff",
                  }}
                  onClick={async (e) => {
                    e.preventDefault();
                    const errors = await validateForm();
                    if (Object.keys(errors).length > 0) {
                      setTouched({ email: true, role: true });
                      setErrors(errors);
                      return;
                    }
                    handleResendInvite(e, values, resetForm);
                  }}
                >
                  Resend Invite
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </div>
      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
      <ConfirmationAlert
        title="A user with this email already exists. You can edit their details or cancel."
        open={showConfirmation}
        confirmText="Edit"
        handle_cancel={() => setShowConfirmation(false)}
        handleYes={Edit_User}
      />
    </MainCard>
  );
};

export default InviteUser;