import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import { roleOptions } from "functions/RoleUtils";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Grid,
  useMediaQuery,
  Avatar,
  Autocomplete,
  TextField,
  Tooltip
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router";
import { FaRegEdit } from "react-icons/fa";


// Components
import Axios from "api/Axios";
import { API } from "api/API";
import Loading from "components/Loading";
import Message from "components/Snackbar/Snackbar";
import useScriptRef from "hooks/useScriptRef";
import MainCard from "ui-component/cards/MainCard";

const EditUser = ({ ...others }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });
  const scriptedRef = useScriptRef();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [userRole, setUserRole] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    // severity: "",
  });
  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      setSelectedImage(file);
    }
  };

  const hiddenFileInputRef = React.useRef(null);

  const handleClickAvatar = () => {
    hiddenFileInputRef.current.click();
  };

  const GetUserDetail = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Get_User_Detail, { id });
      if (response.status === 200) {
        setImagePreview(response?.data?.profile_img);
        setSelectedImage(response?.data?.profile_img);
        setDetails(response.data);
        setIsLoading(false);
        setUserRole(response?.data?.role);
      }
    } catch (error) {
      console.log("Error in fetching user details", error);
      setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
      setIsLoading(false);
    }
  };

  const UpdateUserDetail = async (payload) => {
    try {
      const formData = new FormData();
      for (const key in payload) {
        if (payload[key] !== undefined) {
          formData.append(key, payload[key]);
        }
      }
      if (
        !imagePreview?.startsWith("https://storage.googleapis.com") &&
        selectedImage !== undefined
      ) {
        formData.append("profile_img", selectedImage);
      }

      setIsLoading(true);
      const response = await Axios.Filepost(
        `${API.Update_User_Detail}/${id}`,
        formData
      );
      if (response.status === 200) {
        setSnackbar({
          open: true,
          severity: "success",
          message: "User Data Edited Successfully",
        });
        setIsLoading(false);
        setTimeout(() => {
          navigate("/user-management/users");
        }, 3000);
      }
    } catch (error) {
      console.log("Error in Updating user details", error);
      setSnackbar({
        open: true,
        severity: "error",
        message: error.message,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetUserDetail();
  }, []);

  return (
    <MainCard title="Edit User">
      {details?.email ? (
        <Formik
          initialValues={{
            firstName: details.firstName,
            lastName: details.lastName,
            email: details.email,
            role:
              roleOptions?.filter((option) =>
                details.role?.split(",").includes(option.value)
              ) || [],
          }}
          validationSchema={
            Yup.object().shape({
            firstName: Yup.string()
              .required("First Name is required")
              .max(20, "First Name must not exceed 20 characters")
              .matches(
                /^[a-zA-Z\s]*$/,
                "No special characters allowed in First Name"
              ),
            lastName: Yup.string()
              .max(20, "Last Name must not exceed 20 characters")
              .matches(
                /^[a-zA-Z\s]*$/,
                "No special characters allowed in Last Name"
              ),
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            // role: Yup.string().required("Role is required"),
            role: Yup.array().min(1, "At least one role is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            const payload = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              role: values.role.map((role) => role.value).join(","),
            };
            try {
              UpdateUserDetail(payload);
              if (scriptedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            dirty,
            resetForm,
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              {...others}
              autoComplete="off"
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 1 }}
              >
                {/* Image Upload */}
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <input
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    ref={hiddenFileInputRef}
                  />
                  <Avatar
                    alt="User Image"
                    onClick={handleClickAvatar}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                      width: 100,
                      height: 100,
                      cursor: "pointer",
                      position: "relative",
                      textAlign: "center",
                    }}
                  >
                    {imagePreview && imagePreview !== "null" ? (
                      <img
                        src={imagePreview}
                        alt="User Image"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <Avatar
                        src="/broken-image.jpg"
                        style={{ fontSize: 80 }}
                      />
                    )}
                    {isHovered && (
                      <div
                        style={{
                          position: "absolute",
                          right: 0,
                          left: 0,
                          background: "rgba(0, 0, 0, 0.7)",
                          padding: "32px",
                        }}
                      >
                        <FaRegEdit
                          style={{
                            color: "white",
                            fontSize: "40px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    )}
                  </Avatar>
                </Grid>
              </Grid>

              <Grid container spacing={matchDownSM ? 0 : 2}>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.firstName && errors.firstName)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-firstName">
                      First Name
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email-firstName"
                      type="firstName"
                      value={values.firstName}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{}}
                    />
                    {touched.firstName && errors.firstName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-firstName"
                      >
                        {errors.firstName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.lastName && errors.lastName)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-lastName">
                      Last Name
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email-lastName"
                      type="lastName"
                      value={values.lastName}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{}}
                    />
                    {touched.lastName && errors.lastName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-lastName"
                      >
                        {errors.lastName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="outlined-adornment-email">
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email"
                  type="text"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Email"
                  inputProps={{}}
                  disabled // Add the disabled attribute here
                />
                {touched.email && errors.email && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                style={{ marginTop: 10 }}
                fullWidth
                error={Boolean(touched.role && errors.role)}
              >
                <Autocomplete
                  multiple
                  id="outlined-roles"
                  options={roleOptions}
                  name="role"
                  onBlur={handleBlur}
                  value={values?.role || []}
            
                  disableCloseOnSelect
                 
                  onChange={(event, selectedValues) => {
                    const safeSelectedValues = selectedValues || [];
                    const currentValues = values.role || [];
                    const valueJustClicked = safeSelectedValues.find(
                      (newVal) =>
                        !currentValues.some(
                          (currentVal) => currentVal.value === newVal.value
                        )
                    );

                    if (valueJustClicked) {
                      handleChange({
                        target: {
                          name: "role",
                          value: [...currentValues, valueJustClicked],
                        },
                      });
                    } else {
                      // Find which value was removed by comparing current with selected
                      const removedValue = currentValues.find(
                        (currentVal) =>
                          !safeSelectedValues.some(
                            (newVal) => newVal.value === currentVal.value
                          )
                      );

                      // Remove that value from the current values
                      handleChange({
                        target: {
                          name: "role",
                          value: currentValues.filter(
                            (val) => val.value !== removedValue?.value
                          ),
                        },
                      });
                    }
                 
                  }}
             
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderOption={(props, option, { selected }) => (
                    <li key={props.key} {...props}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Roles"  style={{
                      border:
                        Boolean(touched.role && errors.role) &&
                        "1px solid red",
                      borderRadius: 12,
                      minHeight: "38px",
                    }} />
                  )}
                  style={{ padding: "3px 0" }}
                />

                {touched.role && errors.role && (
                  <FormHelperText error>{errors.role}</FormHelperText>
                )}
              </FormControl>

              <Box sx={{ mt: 2 }} style={{ textAlign: "center" }}>
              <Tooltip 
               PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, 8], // Adjusts vertical spacing (8px in this case)
                    },
                  },
                ],
              }}
              placement="bottom" arrow title={!dirty && selectedImage === imagePreview && "No modifications detected." }>
              <span>
                {/* <AnimateButton> */}
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  disabled={!dirty && selectedImage === imagePreview}
                  sx={{
                    backgroundColor: !dirty && selectedImage === imagePreview ? "#ef9a9a" : "#D72626", 
                    color: !dirty && selectedImage === imagePreview ? "#fff" : "#fff",
                    marginRight: "10px",
                    transition: "0.3s ease-in-out",
                    // cursor: isRoleChanged ? "pointer" : "not-allowed",
                    "&:hover": {
                      backgroundColor: !dirty && selectedImage === imagePreview ? "#B71C1C" : "#D72626", // Darker red when enabled
                    },
                  }}
                  onClick={() => {
                    resetForm();
                    if (selectedImage) {
                      setImagePreview(details.profile_img);
                      setSelectedImage(details.profile_img);
                    } else {
                      setImagePreview(null);
                      setSelectedImage(null);
                    }
                  }}
                >
                  Reset
                </Button>
                </span>
                </Tooltip>
                <Button
                  disabled={!dirty && selectedImage === imagePreview}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{
                    background:
                      !dirty && selectedImage === imagePreview
                        ? "#15223fc9"
                        : "#15223F",
                    color: "#fff",
                  }}
                >
                  Submit
                </Button>
                {/* </AnimateButton> */}
              </Box>
            </form>
          )}
        </Formik>
      ) : null}
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
    </MainCard>
  );
};

export default EditUser;